@use "../globals/forward.scss" as *;

header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  padding: 13px;
  padding-left: 20px;
  background-color: #fff;

  @include tb {
    padding-top: 23px;
    padding-left: 16px;
    padding-bottom: 22px;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 216px;
  height: 16px;

  @include tb {
    width: 213px;
    height: 15px;
  }

  > img {
    width: 100%;
    height: auto;
  }
}

.header__hamburger-menu__icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  display: none;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    display: inline;
  }
}

.header__hamburger-menu__icon[data-is-open="true"] {
  > .hamburger-menu__icon--open {
    display: none;
  }

  > .hamburger-menu__icon--close {
    display: block;
  }
}

.header__hamburger-menu__icon[data-is-open="false"] {
  > .hamburger-menu__icon--open {
    display: block;
  }

  > .hamburger-menu__icon--close {
    display: none;
  }
}

.header__nav {
  display: flex;
  align-items: center;
  gap: 25px;

  > ul {
    display: flex;
    gap: 20px;
  }

  @include tb {
    display: none;
  }
}

.nav__item {
  > a {
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 700;
    color: black;
    text-decoration: none;

    > span {
      display: block;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 9.5px;
      font-weight: 500;
      text-align: center;
      color: #767676;
    }
  }
}

.header__contact-btn {
  width: 128px;
  height: 44px;
  line-height: calc(44px / 15px);

  > a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    background: #00194e;
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    transition: all 0.2s ease;
  }

  > a:hover {
    background: #7f80be;
    color: #ffffff;
  }
}

.header__hamburger-menu {
  display: none;

  @include tb {
    position: absolute;
    top: 62px;
    right: 0;

    &[data-is-open="true"] {
      display: block;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}

.hamburger-menu__item {
  width: 270px;
  height: 60px;
  background-color: #fff;
  transition: all 0.2s ease;

  &:first-child {
    border-radius: 3px 0 0 0;
  }

  &:last-child {
    background-color: #00194e;
    border-radius: 0 0 0 3px;

    > a {
      color: #fff;

      > span {
        color: #fff;
      }
    }
  }

  > a {
    display: block;
    width: calc(100% - 32px);
    height: calc(100% - 31px);
    padding: 15.5px 16px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-decoration: none;

    > span {
      display: block;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #767676;
    }
  }

  &:hover {
    background-color: #767676;

    > a {
      color: #fff;
      > span {
        color: #fff;
      }
    }
  }

  &:hover:last-child {
    background-color: #7f80be;
  }
}
