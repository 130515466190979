@use "../globals/forward.scss" as *;

.mv {
  padding-top: 70px;

  @include tb {
    padding-top: 60px;
  }
}

.mv__top {
  position: relative;
  background-image: url(../assets/images/Desktop/01_mv/Main-v_BELKEN_detail_01.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;
}

.mv__top__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;

  @include tb {
    flex-direction: column;
    gap: 30px;
  }
}

.mv__top__img {
  width: 608px;
  height: 408px;

  > img {
    width: 100%;
    height: auto;
  }

  @include tb {
    width: 360px;
    height: 242px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 242px;
      width: 100%;
      background-color: #000;
      opacity: 30%;
      z-index: -1;
    }
  }
}

.mv__top__title {
  width: 368px;
  font-size: 28px;
  font-weight: bold;
  line-height: calc(40.54px / 28px);
  color: #fff;

  @include tb {
    width: 264px;
    margin-bottom: 35px;
    font-size: 20px;
  }
}

.mv__bottom {
  display: flex;
  justify-content: center;
  background-color: #cecece;

  @include tb {
    position: relative;
    padding: 32px 0px 94px;
  }
}

.mv__bottom__title {
  display: flex;
  padding-left: 72px;
  box-sizing: border-box;
  align-items: center;
  width: 600px;
  height: 250px;
  border-radius: 4px;
  background-color: #fff;
  transform: translateY(-20px);

  @include tb {
    width: 344px;
    height: 180px;
    padding-left: 43px;
    transform: none;
  }

  > h1 {
    font-size: 19px;
    font-weight: bold;
    line-height: calc(27.51px / 19px);
    color: #00194e;

    @include tb {
      font-size: 15px;

      > span {
        display: block;
      }
    }
  }
}

.mv__bottom__img {
  width: 390px;
  height: 270px;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    position: absolute;
    top: 161px;
    left: calc(50% - 78px);
    width: 242px;
    height: 145px;
  }
}
