@use "./forward.scss" as *;

body {
  overflow-x: hidden;
  font-family: "Noto Sans JP", sans-serif;
}

.u-pc-only {
  display: block;
  @include tb {
    display: none;
  }
}
.u-sp-only {
  display: none;
  @include tb {
    display: block;
  }
}
