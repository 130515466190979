@mixin sp {
  @media screen and (max-width: 899px) {
    @content;
  }
}

@mixin tb {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
