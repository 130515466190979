@use "../globals/forward.scss" as *;

.info-graphic {
  background-image: url(../assets/images/Desktop/03_service/BG_Gradient_AVC.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include tb {
    background-image: url(../assets/images/Mobile/MB_BG_Gradient.jpg);
  }
}

.info-graphic__inner {
  padding: 32px 0px;
  margin: 0 auto;
  max-width: 960px;
  max-height: 788px;

  @include tb {
    max-width: 360px;
  }
}

.info-graphic__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 680px;
  height: 185px;
  color: #00194e;
  border-radius: 4px;
  background-color: #fff;

  @include tb {
    width: 344px;
    height: 170px;
    margin: 0 auto;
  }
}

.info-graphic__top__heading {
  margin-bottom: 12px;
  width: 555px;
  font-size: 28px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: calc(32.81px / 28px);

  @include tb {
    margin-bottom: 7px;
    width: 296px;
    font-size: 22px;
  }
}

.info-graphic__top__description {
  width: 555px;
  font-size: 15px;
  font-weight: bold;
  line-height: calc(21.72px / 15px);

  @include tb {
    width: 296px;
    font-size: 14px;
  }
}

.info-graphic__middle {
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(-46px);
  z-index: 10;

  @include tb {
    transform: translateY(-33px);
    align-items: flex-end;
  }
}

.info-graphic__logo {
  flex-shrink: 0;
  padding-top: 106px;
  font-size: 15px;
  font-weight: bold;
  line-height: calc(21.72px / 15px);

  &:first-child {
    color: #fff;

    @include tb {
      transform: translate3d(151px, 41px, 0);
    }
  }

  &:last-child {
    > p {
      text-align: right;
    }

    @include tb {
      transform: translate3d(-151px, 41px, 0);
    }
  }

  @include tb {
    font-size: 11px;
  }
}

.info-graphic__diagram {
  position: relative;
  flex-shrink: 0;
  width: 500px;
  height: 500px;
  z-index: 1;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    width: 360px;
    height: 360px;
  }
}

.tooltip-text {
  position: absolute;
  padding: 20px 18px 20px;
  background-color: hsla(0, 0%, 89%, 0.7);
  color: #000000;
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
  z-index: 10;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;

  @include tb {
    display: none;
  }
}

.tooltip-text1 {
  top: 30px;
  left: 280px;
}
.tooltip-text2 {
  top: 200px;
  left: 425px;
}
.tooltip-text3 {
  top: 380px;
  left: 400px;
}
.tooltip-text4 {
  top: 415px;
  left: 275px;
}
.tooltip-text5 {
  top: 420px;
  left: -185px;
}
.tooltip-text6 {
  top: 295px;
  left: -276px;
}
.tooltip-text7 {
  top: 35px;
  left: -180px;
}
.tooltip-text8 {
  top: -40px;
  left: 285px;
}

.info-graphic__diagram-item {
  position: absolute;
  width: 85px;
  height: 85px;
  display: block;

  @include tb {
    width: 61px;
    height: 61px;
  }

  &:hover + .tooltip-text {
    opacity: 1;
    visibility: visible;
  }
}

.info-graphic__diagram-item1 {
  top: 100px;
  left: 207px;

  @include tb {
    top: 73px;
    left: 149px;
  }
}
.info-graphic__diagram-item2 {
  top: 155px;
  left: 359px;

  @include tb {
    top: 112px;
    left: 258px;
  }
}
.info-graphic__diagram-item3 {
  top: 325px;
  left: 359px;

  @include tb {
    top: 235px;
    left: 258px;
  }
}
.info-graphic__diagram-item4 {
  top: 400px;
  left: 207px;

  @include tb {
    top: 290px;
    left: 150px;
  }
}
.info-graphic__diagram-item5 {
  top: 359px;
  left: 65px;

  @include tb {
    top: 259px;
    left: 48px;
  }
}
.info-graphic__diagram-item6 {
  top: 239px;
  left: 21px;

  @include tb {
    top: 172px;
    left: 15px;
  }
}
.info-graphic__diagram-item7 {
  top: 118px;
  left: 66px;

  @include tb {
    top: 85px;
    left: 47px;
  }
}
.info-graphic__diagram-item8 {
  top: 14px;
  left: 207px;

  @include tb {
    top: 10px;
    left: 150px;
  }
}

.info-graphic__diagram-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;

  &.is-open {
    display: block;
  }
}

.info-graphic__diagram-modal {
  box-sizing: border-box;
  position: fixed;
  padding: 20px;
  width: 296px;
  height: 296px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a6a7a7;
  box-shadow: 0 4px 4px 0 #00000026;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  display: none;

  &.is-open {
    display: flex;
  }
}

.info-graphic__diagram-modal__close {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 7;
    right: 0;
    width: 16px;
    height: 2px;
    background-color: #ffffff;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.info-graphic__img__1 {
  width: 214px;
  height: 26px;
  margin-bottom: 12px;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    width: 106px;
    height: 13px;
    margin-bottom: 5.12px;
  }
}

.info-graphic__img__2 {
  width: 118px;
  height: 21px;
  margin-bottom: 17px;
  margin-left: auto;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    width: 58px;
    height: 10px;
    margin-bottom: 7.68px;
  }
}

.info-graphic__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 576px;
  height: 142px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  line-height: calc(21.72px / 15px);
  color: #fff;
  background-color: #00194e;
  transform: translateY(-39px);

  @include tb {
    margin: 34px auto 0;
    width: 344px;
    height: 170px;
    font-size: 14px;
    transform: translateY(0px);
  }

  > p {
    width: 509px;

    @include tb {
      width: 308px;
    }
  }
}
