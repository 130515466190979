@use "../globals/forward.scss" as *;

.sales-point {
  position: relative;
  z-index: -10;
  background-image: url(../assets/images/Desktop/02_sales_point/SalesPoint_BG_PRONTO_Otemachi_01.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sales-point__inner {
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 960px;
  padding: 48px 0px;
  margin: 0 auto;

  @include tb {
    max-width: 344px;
    padding: 32px 0;
  }
}

.sales-point__unit {
  display: flex;
  align-items: center;
  gap: 16px;

  @include tb {
    flex-direction: column;
    gap: 0px;
  }

  &:nth-child(2) {
    flex-direction: row-reverse;

    @include tb {
      flex-direction: column;
    }
  }
}

.sales-point__text {
  flex: 1;
}

.sales-point__heading {
  padding-bottom: 29px;
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  line-height: calc(27.51px / 19px);

  @include tb {
    padding-bottom: 11px;
    font-size: 15px;
  }
}

.sales-point__description {
  position: relative;
  display: flex;
  align-items: center;
  height: 109px;
  font-size: 15px;
  font-weight: 500;
  line-height: calc(21.72px / 15px);

  @include tb {
    width: 344px;
    height: 90px;
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 480px;
    display: block;
    width: 100vw;
    height: 109px;
    background-image: url(../assets/images/Desktop/02_sales_point/BG_Gradient-band_bl.png);
    background-size: cover;
    background-position: center;
    transform: translate3d(-50%, 5px, 0);

    @include tb {
      background-image: url(../assets/images/Mobile/MB_BG_Gradient-band_bl.png);
      left: 172px;
      height: 90px;
    }
  }
}

.reversal {
  &::before {
    transform: translateX(calc(-50% - 592px)) scale(-1, 1);

    @include tb {
      transform: translate3d(-50%, 5px, 0);
    }
  }
}

.sales-point__img-1 {
  width: 576px;
  height: 420px;

  @include tb {
    width: 242px;
    height: 177px;
    margin-left: 0;
    margin-right: auto;
    transform: translateY(-7px);
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.sales-point__img-2 {
  width: 576px;
  height: 420px;

  @include tb {
    width: 328px;
    height: 239px;
    margin: 0 auto;
    transform: translateY(-7px);
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.sales-point__img-3 {
  width: 368px;
  height: 368px;

  @include tb {
    width: 176px;
    height: 176px;
    margin-left: auto;
    margin-right: 0;
    transform: translateY(-7px);
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
