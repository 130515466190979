@use "../globals/forward.scss" as *;

.contact {
  background-image: url(../assets/images/Desktop/07_contact/Contact_BG.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact__inner {
  max-width: 960px;
  padding: 32px 0 208px;
  margin: 0 auto;
  font-weight: bold;
  color: #fff;

  @include tb {
    max-width: 344px;
    padding: 16px 0 43px;
  }
}

.contact__heading {
  margin-bottom: 88px;
  font-size: 106px;
  font-family: "Roboto", sans-serif;
  text-align: right;
  line-height: calc(124.22px / 106px);

  @include tb {
    margin-bottom: 32px;
    font-size: 48px;
  }
}

.contact__body {
  max-width: 825px;
  margin: 0 auto;

  @include tb {
    max-width: 344px;
  }

  > h3 {
    margin-bottom: 49px;
    font-size: 19px;
    line-height: calc(27.51px / 19px);

    @include tb {
      margin-bottom: 32px;
      font-size: 15px;
    }
  }
}

.contact__contents {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: calc(21.72px / 15px);

  @include tb {
    flex-direction: column;
    gap: 60px;
  }
}

.contact__form {
  flex-shrink: 0;

  @include tb {
    font-size: 14px;
  }
}

.contact__form__btn {
  width: 224px;
  height: 50px;
  margin-top: 60px;
  line-height: 50px;

  @include tb {
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-top: 32px;
  }
  > a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    background: #ffffff;
    text-align: center;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    transition: all 0.3s ease;
  }
  > a:hover {
    background: #767676;
    color: #ffffff;
    margin-left: 0px;
    margin-top: 0px;
    box-shadow: none;
  }
}

.contact__tel {
  flex-shrink: 0;
}

.contact__tel__body {
  margin: 32px 0 22px;

  @include tb {
    margin-bottom: 32px;
  }
}

.contact__tel__number {
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  line-height: calc(40px / 34px);

  @include tb {
    font-size: 22px;
  }
}

.contact__tel__note {
  font-size: 13px;
  font-weight: 500;
  line-height: calc(19px / 13px);

  @include tb {
    font-size: 14px;
  }
}
