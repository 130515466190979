@use "../globals/forward.scss" as *;

footer {
  background-color: #00194e;
}

.footer__inner {
  padding: 22.75px 20px 15px;

  @include tb {
    padding: 0 16px 16px;
  }
}

.footer__pc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tb {
    display: none;
  }
}

.footer__logo {
  width: 216px;
  height: 16px;

  > img {
    width: 100%;
    height: 100%;
  }
}

.footer__nav {
  > ul {
    display: flex;
    gap: 20px;
  }
}

.footer__nav__item {
  > a {
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: calc(12.89px / 11px);
    color: #fff;
    text-decoration: none;

    > span {
      display: block;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 9.5px;
      font-weight: 500;
      line-height: calc(13.76px / 9.5px);
      text-align: center;
      color: #a6a7a7;
    }
  }
}

.footer__link {
  display: none;
  width: 134px;
  height: 36px;
  margin-left: auto;
  margin-right: 0;

  @include tb {
    display: block;
  }

  > a {
    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.footer__copyright {
  margin-top: 49px;
  font-family: "Roboto", sans-serif;
  font-size: 9px;
  font-weight: 300;
  line-height: calc(11px / 9px);
  color: #fff;

  @include tb {
    margin-top: 30px;
    font-size: 8.6px;
  }
}
