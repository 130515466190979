@use "../globals/forward.scss" as *;

.case-study {
  position: relative;
  z-index: 0;
  background-image: url(../assets/images/Desktop/04_case_study/CaseStudy_BG.jpg);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.case-study__inner {
  max-width: 960px;
  padding: 32px 0 48px;
  margin: 0 auto;
  font-weight: bold;
  color: #fff;

  @include tb {
    max-width: 344px;
    padding: 16px 0 32px;
  }
}

.case-study__heading {
  margin-bottom: 32px;
  font-size: 106px;
  font-family: "Roboto", sans-serif;
  line-height: calc(124.22px / 106px);

  @include tb {
    margin-bottom: 16px;
    font-size: 48px;
  }
}

.case-study__unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 48px;

  @include tb {
    flex-direction: column;
    margin-bottom: 32px;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;

    @include tb {
      flex-direction: column;
    }
  }

  &:first-of-type {
    flex-direction: column;
  }

  &:nth-child(3) {
    margin-bottom: 16px;

    @include tb {
      margin-bottom: 32px;
    }
  }

  &:nth-child(4) {
    margin-bottom: -16px;

    @include tb {
      margin-bottom: 32px;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.case-study__text {
  flex: 1;
  margin-right: auto;
}

.case-study__top__heading {
  padding-bottom: 16px;
  font-size: 34px;
  line-height: calc(39.84px / 34px);

  @include tb {
    font-size: 22px;
  }
}

.case-study__top__description {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  width: 656px;
  height: 135px;
  font-size: 15px;
  font-weight: bold;
  color: #000;
  line-height: calc(21.72px / 15px);

  @include tb {
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 0;
    width: auto;
    height: auto;
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 480px;
    display: block;
    width: 100vw;
    height: 135px;
    background-image: url(../assets/images/Desktop/04_case_study/BG_Gradient-band_h135.png);
    background-size: cover;
    background-position: center;
    transform: translateX(-50%);

    @include tb {
      background-image: url(../assets/images/Mobile/MB_BG_Gradient-band_h280.png);
      top: -16px;
      left: 172px;
      height: 280px;
    }
  }
}

.splide {
  width: 712px;
  height: 474px;
  margin-left: auto;

  @include tb {
    width: 296px;
    height: 197px;
    margin: 0 auto;
  }
}

.case-study__slide {
  transform: translateX(-40px);

  @include tb {
    transform: none;
  }
}

.splide__list {
  list-style-type: none;
}

.splide__slide {
  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;

    @include tb {
      border-radius: 3px;
    }
  }
}

.splide__arrow {
  background-color: transparent;
  height: 40px;
  width: 40px;
  opacity: 1;

  &::before {
    content: "";
    height: 20px;
    width: 16px;
    border-radius: 0;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: #fff;

    @include tb {
      height: 10px;
      width: 8px;
    }
  }

  @include tb {
    height: 20px;
    width: 20px;
  }
}

.splide__arrow--prev {
  transform: scale(-1, 1);
  left: -40px;

  @include tb {
    left: -20px;
  }
}

.splide__arrow--next {
  right: -40px;

  @include tb {
    right: -20px;
  }
}

.case-study__unit__heading {
  padding-bottom: 16px;
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  line-height: calc(27.51px / 19px);

  @include tb {
    padding-bottom: 16px;
    font-size: 15px;
  }
}

.case-study__unit__description {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  line-height: calc(18.28px / 13px);

  @include tb {
    align-items: flex-start;
    margin-top: 16px;
    height: auto;
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 480px;
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100%;
    background-image: url(../assets/images/Desktop/04_case_study/BG_Gradient-band_h135.png);
    background-size: cover;
    background-position: center;
    transform: translateX(-50%);

    @include tb {
      background-image: url(../assets/images/Mobile/MB_BG_Gradient-band_h280.png);
      top: -16px;
      left: 172px;
      height: 280px;
    }
  }
}

.h123 {
  height: 123px;

  @include tb {
    height: auto;
  }
}

.h135 {
  height: 135px;

  @include tb {
    height: auto;
  }
}

.h142 {
  height: 142px;

  @include tb {
    height: auto;
  }
}

.h180 {
  height: 180px;

  @include tb {
    height: auto;
  }
}

.h200 {
  height: 200px;

  @include tb {
    height: auto;
  }
}

.case-study__reversal-1 {
  &::before {
    left: -112px;
    transform: translateX(-50%) scale(-1, 1);

    @include tb {
      left: 172px;
    }
  }
}

.case-study__reversal-2 {
  &::before {
    left: -8px;
    transform: translateX(-50%) scale(-1, 1);

    @include tb {
      left: 172px;
    }
  }
}

.case-study__reversal-3 {
  &::before {
    left: 96px;
    transform: translateX(-50%) scale(-1, 1);

    @include tb {
      left: 172px;
    }
  }
}

.case-study__reversal-4 {
  &::before {
    left: -112px;
    transform: translateX(-50%) scale(-1, 1);

    @include tb {
      left: 172px;
    }
  }
}

%img {
  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;

    @include tb {
      border-radius: 3px;
    }
  }
}

.case-study__img-1 {
  width: 576px;
  height: 421px;

  @include tb {
    width: 328px;
    height: 240px;
  }

  @extend %img;
}

.case-study__img-2 {
  width: 368px;
  height: 505px;

  @include tb {
    width: 242px;
    height: 332px;
    margin-left: auto;
  }

  @extend %img;
}

.case-study__img-3 {
  width: 472px;
  height: 647px;

  @include tb {
    width: 242px;
    height: 332px;
    margin-right: auto;
  }

  @extend %img;
}

.case-study__img-4 {
  width: 472px;
  height: 263px;

  @include tb {
    width: 242px;
    height: 135px;
    margin-left: auto;
  }

  @extend %img;
}

.case-study__img-5 {
  width: 368px;
  height: 239px;

  @include tb {
    width: 242px;
    height: 157px;
    margin-right: auto;
  }

  @extend %img;
}

.case-study__img-6 {
  width: 576px;
  height: 358px;

  @include tb {
    width: 328px;
    height: 204px;
  }

  @extend %img;
}

.case-study__img-7 {
  width: 576px;
  height: 240px;

  @include tb {
    width: 242px;
    height: 101px;
    margin-left: auto;
  }

  @extend %img;
}
