html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: Noto Sans JP, sans-serif;
  overflow-x: hidden;
}

.u-pc-only {
  display: block;
}

@media screen and (width <= 1024px) {
  .u-pc-only {
    display: none;
  }
}

.u-sp-only {
  display: none;
}

@media screen and (width <= 1024px) {
  .u-sp-only {
    display: block;
  }
}

.case-study {
  z-index: 0;
  background-image: url("CaseStudy_BG.a524a89e.jpg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.case-study__inner {
  color: #fff;
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0 48px;
  font-weight: bold;
}

@media screen and (width <= 1024px) {
  .case-study__inner {
    max-width: 344px;
    padding: 16px 0 32px;
  }
}

.case-study__heading {
  margin-bottom: 32px;
  font-family: Roboto, sans-serif;
  font-size: 106px;
  line-height: 1.17189;
}

@media screen and (width <= 1024px) {
  .case-study__heading {
    margin-bottom: 16px;
    font-size: 48px;
  }
}

.case-study__unit {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 48px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .case-study__unit {
    flex-direction: column;
    margin-bottom: 32px;
  }
}

.case-study__unit:nth-child(odd) {
  flex-direction: row-reverse;
}

@media screen and (width <= 1024px) {
  .case-study__unit:nth-child(odd) {
    flex-direction: column;
  }
}

.case-study__unit:first-of-type {
  flex-direction: column;
}

.case-study__unit:nth-child(3) {
  margin-bottom: 16px;
}

@media screen and (width <= 1024px) {
  .case-study__unit:nth-child(3) {
    margin-bottom: 32px;
  }
}

.case-study__unit:nth-child(4) {
  margin-bottom: -16px;
}

@media screen and (width <= 1024px) {
  .case-study__unit:nth-child(4) {
    margin-bottom: 32px;
  }
}

.case-study__unit:last-child {
  margin-bottom: 0;
}

.case-study__text {
  flex: 1;
  margin-right: auto;
}

.case-study__top__heading {
  padding-bottom: 16px;
  font-size: 34px;
  line-height: 1.17176;
}

@media screen and (width <= 1024px) {
  .case-study__top__heading {
    font-size: 22px;
  }
}

.case-study__top__description {
  color: #000;
  align-items: center;
  width: 656px;
  height: 135px;
  margin-bottom: 32px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.448;
  display: flex;
  position: relative;
}

@media screen and (width <= 1024px) {
  .case-study__top__description {
    align-items: flex-start;
    width: auto;
    height: auto;
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 14px;
  }
}

.case-study__top__description:before {
  content: "";
  z-index: -1;
  background-image: url("BG_Gradient-band_h135.05d4e16b.png");
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 135px;
  display: block;
  position: absolute;
  left: 480px;
  transform: translateX(-50%);
}

@media screen and (width <= 1024px) {
  .case-study__top__description:before {
    background-image: url("MB_BG_Gradient-band_h280.d04a9260.png");
    height: 280px;
    top: -16px;
    left: 172px;
  }
}

.splide {
  width: 712px;
  height: 474px;
  margin-left: auto;
}

@media screen and (width <= 1024px) {
  .splide {
    width: 296px;
    height: 197px;
    margin: 0 auto;
  }
}

.case-study__slide {
  transform: translateX(-40px);
}

@media screen and (width <= 1024px) {
  .case-study__slide {
    transform: none;
  }
}

.splide__list {
  list-style-type: none;
}

.splide__slide > img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .splide__slide > img {
    border-radius: 3px;
  }
}

.splide__arrow {
  opacity: 1;
  background-color: #0000;
  width: 40px;
  height: 40px;
}

.splide__arrow:before {
  content: "";
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background-color: #fff;
  border-radius: 0;
  width: 16px;
  height: 20px;
}

@media screen and (width <= 1024px) {
  .splide__arrow:before {
    width: 8px;
    height: 10px;
  }

  .splide__arrow {
    width: 20px;
    height: 20px;
  }
}

.splide__arrow--prev {
  left: -40px;
  transform: scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .splide__arrow--prev {
    left: -20px;
  }
}

.splide__arrow--next {
  right: -40px;
}

@media screen and (width <= 1024px) {
  .splide__arrow--next {
    right: -20px;
  }
}

.case-study__unit__heading {
  color: #fff;
  padding-bottom: 16px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.44789;
}

@media screen and (width <= 1024px) {
  .case-study__unit__heading {
    padding-bottom: 16px;
    font-size: 15px;
  }
}

.case-study__unit__description {
  color: #000;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.40615;
  display: flex;
  position: relative;
}

@media screen and (width <= 1024px) {
  .case-study__unit__description {
    align-items: flex-start;
    height: auto;
    margin-top: 16px;
    font-size: 14px;
  }
}

.case-study__unit__description:before {
  content: "";
  z-index: -1;
  background-image: url("BG_Gradient-band_h135.05d4e16b.png");
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100%;
  display: block;
  position: absolute;
  left: 480px;
  transform: translateX(-50%);
}

@media screen and (width <= 1024px) {
  .case-study__unit__description:before {
    background-image: url("MB_BG_Gradient-band_h280.d04a9260.png");
    height: 280px;
    top: -16px;
    left: 172px;
  }
}

.h123 {
  height: 123px;
}

@media screen and (width <= 1024px) {
  .h123 {
    height: auto;
  }
}

.h135 {
  height: 135px;
}

@media screen and (width <= 1024px) {
  .h135 {
    height: auto;
  }
}

.h142 {
  height: 142px;
}

@media screen and (width <= 1024px) {
  .h142 {
    height: auto;
  }
}

.h180 {
  height: 180px;
}

@media screen and (width <= 1024px) {
  .h180 {
    height: auto;
  }
}

.h200 {
  height: 200px;
}

@media screen and (width <= 1024px) {
  .h200 {
    height: auto;
  }
}

.case-study__reversal-1:before {
  left: -112px;
  transform: translateX(-50%)scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .case-study__reversal-1:before {
    left: 172px;
  }
}

.case-study__reversal-2:before {
  left: -8px;
  transform: translateX(-50%)scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .case-study__reversal-2:before {
    left: 172px;
  }
}

.case-study__reversal-3:before {
  left: 96px;
  transform: translateX(-50%)scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .case-study__reversal-3:before {
    left: 172px;
  }
}

.case-study__reversal-4:before {
  left: -112px;
  transform: translateX(-50%)scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .case-study__reversal-4:before {
    left: 172px;
  }
}

.case-study__img-7 > img, .case-study__img-6 > img, .case-study__img-5 > img, .case-study__img-4 > img, .case-study__img-3 > img, .case-study__img-2 > img, .case-study__img-1 > img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .case-study__img-7 > img, .case-study__img-6 > img, .case-study__img-5 > img, .case-study__img-4 > img, .case-study__img-3 > img, .case-study__img-2 > img, .case-study__img-1 > img {
    border-radius: 3px;
  }
}

.case-study__img-1 {
  width: 576px;
  height: 421px;
}

@media screen and (width <= 1024px) {
  .case-study__img-1 {
    width: 328px;
    height: 240px;
  }
}

.case-study__img-2 {
  width: 368px;
  height: 505px;
}

@media screen and (width <= 1024px) {
  .case-study__img-2 {
    width: 242px;
    height: 332px;
    margin-left: auto;
  }
}

.case-study__img-3 {
  width: 472px;
  height: 647px;
}

@media screen and (width <= 1024px) {
  .case-study__img-3 {
    width: 242px;
    height: 332px;
    margin-right: auto;
  }
}

.case-study__img-4 {
  width: 472px;
  height: 263px;
}

@media screen and (width <= 1024px) {
  .case-study__img-4 {
    width: 242px;
    height: 135px;
    margin-left: auto;
  }
}

.case-study__img-5 {
  width: 368px;
  height: 239px;
}

@media screen and (width <= 1024px) {
  .case-study__img-5 {
    width: 242px;
    height: 157px;
    margin-right: auto;
  }
}

.case-study__img-6 {
  width: 576px;
  height: 358px;
}

@media screen and (width <= 1024px) {
  .case-study__img-6 {
    width: 328px;
    height: 204px;
  }
}

.case-study__img-7 {
  width: 576px;
  height: 240px;
}

@media screen and (width <= 1024px) {
  .case-study__img-7 {
    width: 242px;
    height: 101px;
    margin-left: auto;
  }
}

.contact {
  background-image: url("Contact_BG.d90713c2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact__inner {
  color: #fff;
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0 208px;
  font-weight: bold;
}

@media screen and (width <= 1024px) {
  .contact__inner {
    max-width: 344px;
    padding: 16px 0 43px;
  }
}

.contact__heading {
  text-align: right;
  margin-bottom: 88px;
  font-family: Roboto, sans-serif;
  font-size: 106px;
  line-height: 1.17189;
}

@media screen and (width <= 1024px) {
  .contact__heading {
    margin-bottom: 32px;
    font-size: 48px;
  }
}

.contact__body {
  max-width: 825px;
  margin: 0 auto;
}

@media screen and (width <= 1024px) {
  .contact__body {
    max-width: 344px;
  }
}

.contact__body > h3 {
  margin-bottom: 49px;
  font-size: 19px;
  line-height: 1.44789;
}

@media screen and (width <= 1024px) {
  .contact__body > h3 {
    margin-bottom: 32px;
    font-size: 15px;
  }
}

.contact__contents {
  justify-content: space-between;
  font-size: 15px;
  line-height: 1.448;
  display: flex;
}

@media screen and (width <= 1024px) {
  .contact__contents {
    flex-direction: column;
    gap: 60px;
  }
}

.contact__form {
  flex-shrink: 0;
}

@media screen and (width <= 1024px) {
  .contact__form {
    font-size: 14px;
  }
}

.contact__form__btn {
  width: 224px;
  height: 50px;
  margin-top: 60px;
  line-height: 50px;
}

@media screen and (width <= 1024px) {
  .contact__form__btn {
    width: 100%;
    height: 60px;
    margin-top: 32px;
    line-height: 60px;
  }
}

.contact__form__btn > a {
  text-align: center;
  color: #000;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.contact__form__btn > a:hover {
  color: #fff;
  box-shadow: none;
  background: #767676;
  margin-top: 0;
  margin-left: 0;
}

.contact__tel {
  flex-shrink: 0;
}

.contact__tel__body {
  margin: 32px 0 22px;
}

@media screen and (width <= 1024px) {
  .contact__tel__body {
    margin-bottom: 32px;
  }
}

.contact__tel__number {
  font-family: Roboto, sans-serif;
  font-size: 34px;
  line-height: 1.17647;
}

@media screen and (width <= 1024px) {
  .contact__tel__number {
    font-size: 22px;
  }
}

.contact__tel__note {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.46154;
}

@media screen and (width <= 1024px) {
  .contact__tel__note {
    font-size: 14px;
  }
}

.info-graphic {
  background-image: url("BG_Gradient_AVC.50ac7dac.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (width <= 1024px) {
  .info-graphic {
    background-image: url("MB_BG_Gradient.94a7e1aa.jpg");
  }
}

.info-graphic__inner {
  max-width: 960px;
  max-height: 788px;
  margin: 0 auto;
  padding: 32px 0;
}

@media screen and (width <= 1024px) {
  .info-graphic__inner {
    max-width: 360px;
  }
}

.info-graphic__top {
  color: #00194e;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 680px;
  height: 185px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .info-graphic__top {
    width: 344px;
    height: 170px;
    margin: 0 auto;
  }
}

.info-graphic__top__heading {
  width: 555px;
  margin-bottom: 12px;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.17179;
}

@media screen and (width <= 1024px) {
  .info-graphic__top__heading {
    width: 296px;
    margin-bottom: 7px;
    font-size: 22px;
  }
}

.info-graphic__top__description {
  width: 555px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.448;
}

@media screen and (width <= 1024px) {
  .info-graphic__top__description {
    width: 296px;
    font-size: 14px;
  }
}

.info-graphic__middle {
  z-index: 10;
  justify-content: center;
  display: flex;
  position: relative;
  transform: translateY(-46px);
}

@media screen and (width <= 1024px) {
  .info-graphic__middle {
    align-items: flex-end;
    transform: translateY(-33px);
  }
}

.info-graphic__logo {
  flex-shrink: 0;
  padding-top: 106px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.448;
}

.info-graphic__logo:first-child {
  color: #fff;
}

@media screen and (width <= 1024px) {
  .info-graphic__logo:first-child {
    transform: translate3d(151px, 41px, 0);
  }
}

.info-graphic__logo:last-child > p {
  text-align: right;
}

@media screen and (width <= 1024px) {
  .info-graphic__logo:last-child {
    transform: translate3d(-151px, 41px, 0);
  }

  .info-graphic__logo {
    font-size: 11px;
  }
}

.info-graphic__diagram {
  z-index: 1;
  flex-shrink: 0;
  width: 500px;
  height: 500px;
  position: relative;
}

.info-graphic__diagram > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram {
    width: 360px;
    height: 360px;
  }
}

.tooltip-text {
  color: #000;
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  background-color: #e3e3e3b3;
  border-radius: 4px;
  padding: 20px 18px;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  position: absolute;
}

@media screen and (width <= 1024px) {
  .tooltip-text {
    display: none;
  }
}

.tooltip-text1 {
  top: 30px;
  left: 280px;
}

.tooltip-text2 {
  top: 200px;
  left: 425px;
}

.tooltip-text3 {
  top: 380px;
  left: 400px;
}

.tooltip-text4 {
  top: 415px;
  left: 275px;
}

.tooltip-text5 {
  top: 420px;
  left: -185px;
}

.tooltip-text6 {
  top: 295px;
  left: -276px;
}

.tooltip-text7 {
  top: 35px;
  left: -180px;
}

.tooltip-text8 {
  top: -40px;
  left: 285px;
}

.info-graphic__diagram-item {
  width: 85px;
  height: 85px;
  display: block;
  position: absolute;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item {
    width: 61px;
    height: 61px;
  }
}

.info-graphic__diagram-item:hover + .tooltip-text {
  opacity: 1;
  visibility: visible;
}

.info-graphic__diagram-item1 {
  top: 100px;
  left: 207px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item1 {
    top: 73px;
    left: 149px;
  }
}

.info-graphic__diagram-item2 {
  top: 155px;
  left: 359px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item2 {
    top: 112px;
    left: 258px;
  }
}

.info-graphic__diagram-item3 {
  top: 325px;
  left: 359px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item3 {
    top: 235px;
    left: 258px;
  }
}

.info-graphic__diagram-item4 {
  top: 400px;
  left: 207px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item4 {
    top: 290px;
    left: 150px;
  }
}

.info-graphic__diagram-item5 {
  top: 359px;
  left: 65px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item5 {
    top: 259px;
    left: 48px;
  }
}

.info-graphic__diagram-item6 {
  top: 239px;
  left: 21px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item6 {
    top: 172px;
    left: 15px;
  }
}

.info-graphic__diagram-item7 {
  top: 118px;
  left: 66px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item7 {
    top: 85px;
    left: 47px;
  }
}

.info-graphic__diagram-item8 {
  top: 14px;
  left: 207px;
}

@media screen and (width <= 1024px) {
  .info-graphic__diagram-item8 {
    top: 10px;
    left: 150px;
  }
}

.info-graphic__diagram-modal-wrap {
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.info-graphic__diagram-modal-wrap.is-open {
  display: block;
}

.info-graphic__diagram-modal {
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  background-color: #a6a7a7;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 296px;
  padding: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 4px #00000026;
}

.info-graphic__diagram-modal.is-open {
  display: flex;
}

.info-graphic__diagram-modal__close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 22px;
  right: 22px;
}

.info-graphic__diagram-modal__close:before, .info-graphic__diagram-modal__close:after {
  content: "";
  background-color: #fff;
  width: 16px;
  height: 2px;
  position: absolute;
  top: 7px;
  right: 0;
}

.info-graphic__diagram-modal__close:before {
  transform: rotate(45deg);
}

.info-graphic__diagram-modal__close:after {
  transform: rotate(-45deg);
}

.info-graphic__img__1 {
  width: 214px;
  height: 26px;
  margin-bottom: 12px;
}

.info-graphic__img__1 > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .info-graphic__img__1 {
    width: 106px;
    height: 13px;
    margin-bottom: 5.12px;
  }
}

.info-graphic__img__2 {
  width: 118px;
  height: 21px;
  margin-bottom: 17px;
  margin-left: auto;
}

.info-graphic__img__2 > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .info-graphic__img__2 {
    width: 58px;
    height: 10px;
    margin-bottom: 7.68px;
  }
}

.info-graphic__bottom {
  color: #fff;
  background-color: #00194e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 576px;
  height: 142px;
  margin-left: auto;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.448;
  display: flex;
  transform: translateY(-39px);
}

@media screen and (width <= 1024px) {
  .info-graphic__bottom {
    width: 344px;
    height: 170px;
    margin: 34px auto 0;
    font-size: 14px;
    transform: translateY(0);
  }
}

.info-graphic__bottom > p {
  width: 509px;
}

@media screen and (width <= 1024px) {
  .info-graphic__bottom > p {
    width: 308px;
  }
}

.sales-point {
  z-index: -10;
  background-image: url("SalesPoint_BG_PRONTO_Otemachi_01.b0fdb534.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sales-point__inner {
  flex-direction: column;
  gap: 48px;
  max-width: 960px;
  margin: 0 auto;
  padding: 48px 0;
  display: flex;
}

@media screen and (width <= 1024px) {
  .sales-point__inner {
    max-width: 344px;
    padding: 32px 0;
  }
}

.sales-point__unit {
  align-items: center;
  gap: 16px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .sales-point__unit {
    flex-direction: column;
    gap: 0;
  }
}

.sales-point__unit:nth-child(2) {
  flex-direction: row-reverse;
}

@media screen and (width <= 1024px) {
  .sales-point__unit:nth-child(2) {
    flex-direction: column;
  }
}

.sales-point__text {
  flex: 1;
}

.sales-point__heading {
  color: #fff;
  padding-bottom: 29px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.44789;
}

@media screen and (width <= 1024px) {
  .sales-point__heading {
    padding-bottom: 11px;
    font-size: 15px;
  }
}

.sales-point__description {
  align-items: center;
  height: 109px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.448;
  display: flex;
  position: relative;
}

@media screen and (width <= 1024px) {
  .sales-point__description {
    width: 344px;
    height: 90px;
    font-size: 14px;
  }
}

.sales-point__description:before {
  content: "";
  z-index: -1;
  background-image: url("BG_Gradient-band_bl.d26d0df8.png");
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 109px;
  display: block;
  position: absolute;
  left: 480px;
  transform: translate3d(-50%, 5px, 0);
}

@media screen and (width <= 1024px) {
  .sales-point__description:before {
    background-image: url("MB_BG_Gradient-band_bl.0f6f1f6b.png");
    height: 90px;
    left: 172px;
  }
}

.reversal:before {
  transform: translateX(calc(-50% - 592px))scale(-1, 1);
}

@media screen and (width <= 1024px) {
  .reversal:before {
    transform: translate3d(-50%, 5px, 0);
  }
}

.sales-point__img-1 {
  width: 576px;
  height: 420px;
}

@media screen and (width <= 1024px) {
  .sales-point__img-1 {
    width: 242px;
    height: 177px;
    margin-left: 0;
    margin-right: auto;
    transform: translateY(-7px);
  }
}

.sales-point__img-1 > img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.sales-point__img-2 {
  width: 576px;
  height: 420px;
}

@media screen and (width <= 1024px) {
  .sales-point__img-2 {
    width: 328px;
    height: 239px;
    margin: 0 auto;
    transform: translateY(-7px);
  }
}

.sales-point__img-2 > img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.sales-point__img-3 {
  width: 368px;
  height: 368px;
}

@media screen and (width <= 1024px) {
  .sales-point__img-3 {
    width: 176px;
    height: 176px;
    margin-left: auto;
    margin-right: 0;
    transform: translateY(-7px);
  }
}

.sales-point__img-3 > img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

footer {
  background-color: #00194e;
}

.footer__inner {
  padding: 22.75px 20px 15px;
}

@media screen and (width <= 1024px) {
  .footer__inner {
    padding: 0 16px 16px;
  }
}

.footer__pc {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (width <= 1024px) {
  .footer__pc {
    display: none;
  }
}

.footer__logo {
  width: 216px;
  height: 16px;
}

.footer__logo > img {
  width: 100%;
  height: 100%;
}

.footer__nav > ul {
  gap: 20px;
  display: flex;
}

.footer__nav__item > a {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.17182;
  text-decoration: none;
}

.footer__nav__item > a > span {
  text-align: center;
  color: #a6a7a7;
  font-family: Noto Sans JP, sans-serif;
  font-size: 9.5px;
  font-weight: 500;
  line-height: 1.44842;
  display: block;
}

.footer__link {
  width: 134px;
  height: 36px;
  margin-left: auto;
  margin-right: 0;
  display: none;
}

@media screen and (width <= 1024px) {
  .footer__link {
    display: block;
  }
}

.footer__link > a > img {
  width: 100%;
  height: 100%;
}

.footer__copyright {
  color: #fff;
  margin-top: 49px;
  font-family: Roboto, sans-serif;
  font-size: 9px;
  font-weight: 300;
  line-height: 1.22222;
}

@media screen and (width <= 1024px) {
  .footer__copyright {
    margin-top: 30px;
    font-size: 8.6px;
  }
}

header {
  z-index: 100;
  background-color: #fff;
  padding: 13px 13px 13px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (width <= 1024px) {
  header {
    padding-top: 23px;
    padding-bottom: 22px;
    padding-left: 16px;
  }
}

.header__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo {
  width: 216px;
  height: 16px;
}

@media screen and (width <= 1024px) {
  .header__logo {
    width: 213px;
    height: 15px;
  }
}

.header__logo > img {
  width: 100%;
  height: auto;
}

.header__hamburger-menu__icon {
  width: 60px;
  height: 60px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.header__hamburger-menu__icon > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .header__hamburger-menu__icon {
    display: inline;
  }
}

.header__hamburger-menu__icon[data-is-open="true"] > .hamburger-menu__icon--open {
  display: none;
}

.header__hamburger-menu__icon[data-is-open="true"] > .hamburger-menu__icon--close, .header__hamburger-menu__icon[data-is-open="false"] > .hamburger-menu__icon--open {
  display: block;
}

.header__hamburger-menu__icon[data-is-open="false"] > .hamburger-menu__icon--close {
  display: none;
}

.header__nav {
  align-items: center;
  gap: 25px;
  display: flex;
}

.header__nav > ul {
  gap: 20px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .header__nav {
    display: none;
  }
}

.nav__item > a {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

.nav__item > a > span {
  text-align: center;
  color: #767676;
  font-family: Noto Sans JP, sans-serif;
  font-size: 9.5px;
  font-weight: 500;
  display: block;
}

.header__contact-btn {
  width: 128px;
  height: 44px;
  line-height: 2.93333;
}

.header__contact-btn > a {
  text-align: center;
  color: #fff;
  background: #00194e;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s;
  display: block;
}

.header__contact-btn > a:hover {
  color: #fff;
  background: #7f80be;
}

.header__hamburger-menu {
  display: none;
}

@media screen and (width <= 1024px) {
  .header__hamburger-menu {
    position: absolute;
    top: 62px;
    right: 0;
  }

  .header__hamburger-menu[data-is-open="true"] {
    display: block;
  }

  .header__hamburger-menu > ul {
    flex-direction: column;
    gap: 2px;
    display: flex;
  }
}

.hamburger-menu__item {
  background-color: #fff;
  width: 270px;
  height: 60px;
  transition: all .2s;
}

.hamburger-menu__item:first-child {
  border-radius: 3px 0 0;
}

.hamburger-menu__item:last-child {
  background-color: #00194e;
  border-radius: 0 0 0 3px;
}

.hamburger-menu__item:last-child > a, .hamburger-menu__item:last-child > a > span {
  color: #fff;
}

.hamburger-menu__item > a {
  color: #000;
  width: calc(100% - 32px);
  height: calc(100% - 31px);
  padding: 15.5px 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.hamburger-menu__item > a > span {
  color: #767676;
  font-family: Noto Sans JP, sans-serif;
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.hamburger-menu__item:hover {
  background-color: #767676;
}

.hamburger-menu__item:hover > a, .hamburger-menu__item:hover > a > span {
  color: #fff;
}

.hamburger-menu__item:hover:last-child {
  background-color: #7f80be;
}

.mv {
  padding-top: 70px;
}

@media screen and (width <= 1024px) {
  .mv {
    padding-top: 60px;
  }
}

.mv__top {
  z-index: -2;
  background-image: url("Main-v_BELKEN_detail_01.1f4157b8.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.mv__top__inner {
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .mv__top__inner {
    flex-direction: column;
    gap: 30px;
  }
}

.mv__top__img {
  width: 608px;
  height: 408px;
}

.mv__top__img > img {
  width: 100%;
  height: auto;
}

@media screen and (width <= 1024px) {
  .mv__top__img {
    width: 360px;
    height: 242px;
  }

  .mv__top__img:before {
    content: "";
    opacity: .3;
    z-index: -1;
    background-color: #000;
    width: 100%;
    height: 242px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.mv__top__title {
  color: #fff;
  width: 368px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.44786;
}

@media screen and (width <= 1024px) {
  .mv__top__title {
    width: 264px;
    margin-bottom: 35px;
    font-size: 20px;
  }
}

.mv__bottom {
  background-color: #cecece;
  justify-content: center;
  display: flex;
}

@media screen and (width <= 1024px) {
  .mv__bottom {
    padding: 32px 0 94px;
    position: relative;
  }
}

.mv__bottom__title {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  width: 600px;
  height: 250px;
  padding-left: 72px;
  display: flex;
  transform: translateY(-20px);
}

@media screen and (width <= 1024px) {
  .mv__bottom__title {
    width: 344px;
    height: 180px;
    padding-left: 43px;
    transform: none;
  }
}

.mv__bottom__title > h1 {
  color: #00194e;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.44789;
}

@media screen and (width <= 1024px) {
  .mv__bottom__title > h1 {
    font-size: 15px;
  }

  .mv__bottom__title > h1 > span {
    display: block;
  }
}

.mv__bottom__img {
  width: 390px;
  height: 270px;
}

.mv__bottom__img > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .mv__bottom__img {
    width: 242px;
    height: 145px;
    position: absolute;
    top: 161px;
    left: calc(50% - 78px);
  }
}

.our-team {
  background-image: url("BG_Gradient_OurTeam.34204aeb.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (width <= 1024px) {
  .our-team {
    background-image: url("MB_BG_Gradient_Team.79a44817.jpg");
  }
}

.our-team__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0 48px;
}

@media screen and (width <= 1024px) {
  .our-team__inner {
    max-width: 344px;
    padding: 16px 0 32px;
  }
}

.our-team__heading {
  color: #00194e;
  margin-bottom: 32px;
  font-family: Roboto, sans-serif;
  font-size: 106px;
  font-weight: bold;
  line-height: 1.17189;
}

@media screen and (width <= 1024px) {
  .our-team__heading {
    margin-bottom: 16px;
    font-size: 46px;
  }
}

.our-team__body {
  align-items: center;
  gap: 27.5px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .our-team__body {
    flex-direction: column;
    gap: 16px;
  }
}

.our-team__unit--gray, .our-team__unit--white {
  border-radius: 4px;
  width: 432px;
  height: 432px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.448;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray, .our-team__unit--white {
    border-radius: 3px;
    width: 344px;
    height: 380px;
  }
}

.our-team__unit--gray > .our-team__unit__heading, .our-team__unit--white > .our-team__unit__heading {
  color: #fff;
  border-radius: 4px 4px 0 0;
  justify-content: center;
  align-items: center;
  height: 55px;
  display: flex;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray > .our-team__unit__heading, .our-team__unit--white > .our-team__unit__heading {
    border-radius: 3px 3px 0 0;
    height: 50px;
  }
}

.our-team__unit--gray > .our-team__unit__body, .our-team__unit--white > .our-team__unit__body {
  padding: 40px 32px 24px;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray > .our-team__unit__body, .our-team__unit--white > .our-team__unit__body {
    padding: 32px 16px 14px;
    font-size: 14px;
  }
}

.our-team__unit--gray > .our-team__unit__body > .our-team__body__logo, .our-team__unit--white > .our-team__unit__body > .our-team__body__logo {
  text-align: center;
  height: 28px;
  margin-bottom: 30px;
}

.our-team__unit--gray > .our-team__unit__body > .our-team__body__logo > img, .our-team__unit--white > .our-team__unit__body > .our-team__body__logo > img {
  width: auto;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray > .our-team__unit__body > .our-team__body__logo, .our-team__unit--white > .our-team__unit__body > .our-team__body__logo {
    height: 20px;
    margin-bottom: 16px;
  }
}

.our-team__unit--gray > .our-team__unit__body > .our-team__unit__text, .our-team__unit--white > .our-team__unit__body > .our-team__unit__text {
  flex-wrap: nowrap;
  margin-bottom: 21px;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray > .our-team__unit__body > .our-team__unit__text, .our-team__unit--white > .our-team__unit__body > .our-team__unit__text {
    margin-bottom: 16px;
  }
}

.our-team__unit__link--black, .our-team__unit__link--blue {
  text-align: right;
  position: relative;
}

.our-team__unit__link--black > a, .our-team__unit__link--blue > a {
  text-decoration: none;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 20px;
}

.our-team__unit__link--black > a:after, .our-team__unit__link--blue > a:after {
  content: "";
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  width: 10px;
  height: 12px;
  margin: auto 0;
  transition: all .2s;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
}

@media screen and (width <= 1024px) {
  .our-team__unit__link--black > a:after, .our-team__unit__link--blue > a:after {
    width: 8px;
    height: 10px;
  }
}

.our-team__unit--white {
  background-color: #fff;
}

.our-team__unit--white > .our-team__unit__heading {
  background-color: #00194e;
}

.our-team__unit--white > .our-team__unit__body {
  color: #00194e;
}

.our-team__unit--gray {
  background-color: #cecece;
}

.our-team__unit--gray > .our-team__unit__heading {
  background-color: #000;
}

.our-team__unit--gray > .our-team__unit__body {
  color: #000;
}

.our-team__unit--gray > .our-team__unit__body > .our-team__unit__text {
  width: 400px;
}

@media screen and (width <= 1024px) {
  .our-team__unit--gray > .our-team__unit__body > .our-team__unit__text {
    width: auto;
  }
}

.our-team__img {
  width: 41px;
  height: 48px;
}

.our-team__img > img {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .our-team__img {
    width: 20px;
    height: 20px;
  }
}

.pc {
  display: block;
}

@media screen and (width <= 1024px) {
  .pc {
    display: none;
  }
}

.tb {
  display: none;
}

@media screen and (width <= 1024px) {
  .tb {
    display: block;
  }
}

.our-team__unit__link--blue > a {
  color: #00194e;
}

.our-team__unit__link--blue > a:hover {
  color: #7f80be;
}

.our-team__unit__link--blue > a:after {
  background-color: #00194e;
}

.our-team__unit__link--blue > a:hover:after {
  background-color: #7f80be;
}

.our-team__unit__link--black > a {
  color: #000;
}

.our-team__unit__link--black > a:hover {
  color: #767676;
}

.our-team__unit__link--black > a:after {
  background-color: #000;
}

.our-team__unit__link--black > a:hover:after {
  background-color: #767676;
}

.projects {
  z-index: 0;
  background-image: url("Projects_BG.742b6394.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.projects__inner {
  padding: 32px 0 48px;
}

@media screen and (width <= 1024px) {
  .projects__inner {
    padding: 16px 0 32px;
  }
}

.projects__title {
  text-align: right;
  color: #fff;
  max-width: 972px;
  margin: 0 auto 32px;
  font-size: 106px;
  font-weight: 700;
}

@media screen and (width <= 1024px) {
  .projects__title {
    max-width: 344px;
    margin: 0 auto 16px;
    font-size: 48px;
  }
}

.projects__heading, .projects__heading--small {
  color: #fff;
  max-width: 966px;
  margin: 0 auto 16px;
  font-size: 34px;
  font-weight: 700;
}

@media screen and (width <= 1024px) {
  .projects__heading, .projects__heading--small {
    max-width: 344px;
    font-size: 22px;
  }
}

.projects__heading--small {
  max-width: 842px;
}

@media screen and (width <= 1024px) {
  .projects__heading--small {
    max-width: 344px;
  }
}

.projects__text-bg, .projects__text-bg--small {
  background-image: linear-gradient(90deg, #fff, #fff0);
  width: 100%;
  padding: 24px 0 26px;
}

@media screen and (width <= 1024px) {
  .projects__text-bg, .projects__text-bg--small {
    background-image: none;
    padding: 16px 0;
    position: relative;
  }

  .projects__text-bg:before, .projects__text-bg--small:before {
    content: "";
    z-index: -1;
    background-image: url("MB_BG_Gradient-band_h266v.68a71ad0.png");
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 266px;
    display: block;
    position: absolute;
    top: 0;
  }
}

.projects__text-bg > p, .projects__text-bg--small > p {
  max-width: 966px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (width <= 1024px) {
  .projects__text-bg > p, .projects__text-bg--small > p {
    max-width: 344px;
    font-size: 14px;
  }
}

.projects__text-bg--small {
  background-image: linear-gradient(90deg, #fff0, #fff);
}

@media screen and (width <= 1024px) {
  .projects__text-bg--small {
    background-image: none;
    padding: 16px 0;
    position: relative;
  }

  .projects__text-bg--small:before {
    content: "";
    z-index: -1;
    background-image: url("MB_BG_Gradient-band_h266v.68a71ad0.png");
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 266px;
    display: block;
    position: absolute;
    top: 0;
  }
}

.projects__text-bg--small > p {
  max-width: 842px;
  margin: 0 auto;
}

@media screen and (width <= 1024px) {
  .projects__text-bg--small > p {
    max-width: 344px;
    font-size: 14px;
  }
}

.projects1 {
  margin: 32px auto 48px;
  transform: translateX(-36px);
}

@media screen and (width <= 1024px) {
  .projects1 {
    width: 296px;
    height: 197px;
    margin: 0 auto 32px;
    transform: none;
  }
}

.projects2 {
  margin: 32px auto 48px;
  transform: translateX(36px);
}

@media screen and (width <= 1024px) {
  .projects2 {
    width: 296px;
    height: 197px;
    margin: 0 auto 32px;
    transform: none;
  }
}

.projects3 {
  margin: 32px auto 0;
  transform: translateX(-36px);
}

@media screen and (width <= 1024px) {
  .projects3 {
    width: 296px;
    height: 197px;
    margin: 0 auto;
    transform: none;
  }
}

.projects__image {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1024px) {
  .projects__image {
    border-radius: 3px;
  }
}

/*# sourceMappingURL=index.f676e7c4.css.map */
