@use "../globals/forward.scss" as *;

.our-team {
  background-image: url(../assets/images/Desktop/06_team/BG_Gradient_OurTeam.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include tb {
    background-image: url(../assets/images/Mobile/MB_BG_Gradient_Team.jpg);
  }
}

.our-team__inner {
  padding: 32px 0 48px;
  margin: 0 auto;
  max-width: 960px;

  @include tb {
    padding: 16px 0px 32px;
    max-width: 344px;
  }
}

.our-team__heading {
  margin-bottom: 32px;
  font-size: 106px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: calc(124.22px / 106px);
  color: #00194e;

  @include tb {
    font-size: 46px;
    margin-bottom: 16px;
  }
}

.our-team__body {
  display: flex;
  align-items: center;
  gap: 27.5px;

  @include tb {
    flex-direction: column;
    gap: 16px;
  }
}

%unit-base {
  width: 432px;
  height: 432px;
  font-size: 15px;
  font-weight: bold;
  line-height: calc(21.72px / 15px);
  border-radius: 4px;

  @include tb {
    width: 344px;
    height: 380px;
    border-radius: 3px;
  }

  > .our-team__unit__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    color: #fff;
    border-radius: 4px 4px 0 0;

    @include tb {
      height: 50px;
      border-radius: 3px 3px 0 0;
    }
  }

  > .our-team__unit__body {
    padding: 40px 32px 24px;

    @include tb {
      padding: 32px 16px 14px;
      font-size: 14px;
    }

    > .our-team__body__logo {
      height: 28px;
      margin-bottom: 30px;
      text-align: center;
      > img {
        height: 100%;
        width: auto;
      }

      @include tb {
        height: 20px;
        margin-bottom: 16px;
      }
    }

    > .our-team__unit__text {
      margin-bottom: 21px;
      flex-wrap: nowrap;

      @include tb {
        margin-bottom: 16px;
      }
    }
  }
}

%link__base {
  position: relative;
  text-align: right;

  > a {
    position: absolute;
    top: 0;
    right: 20px;
    text-decoration: none;
    transition: all 0.2s ease;

    &::after {
      content: "";
      display: inline-block;
      margin: auto 0;
      height: 12px;
      width: 10px;
      position: absolute;
      top: 0;
      right: -20px;
      bottom: 0;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
      transition: all 0.2s ease;

      @include tb {
        height: 10px;
        width: 8px;
      }
    }
  }
}

.our-team__unit--white {
  @extend %unit-base;
  background-color: white;

  > .our-team__unit__heading {
    background-color: #00194e;
  }

  > .our-team__unit__body {
    color: #00194e;
  }
}

.our-team__unit--gray {
  @extend %unit-base;
  background-color: #cecece;

  > .our-team__unit__heading {
    background-color: #000;
  }

  > .our-team__unit__body {
    color: #000;

    > .our-team__unit__text {
      width: 400px;

      @include tb {
        width: auto;
      }
    }
  }
}

.our-team__img {
  width: 41px;
  height: 48px;

  > img {
    width: 100%;
    height: 100%;
  }

  @include tb {
    width: 20px;
    height: 20px;
  }
}

.pc {
  display: block;
  @include tb {
    display: none;
  }
}

.tb {
  display: none;
  @include tb {
    display: block;
  }
}

.our-team__unit__link--blue {
  @extend %link__base;
  > a {
    color: #00194e;

    &:hover {
      color: #7f80be;
    }

    &::after {
      background-color: #00194e;
    }

    &:hover::after {
      background-color: #7f80be;
    }
  }
}

.our-team__unit__link--black {
  @extend %link__base;
  > a {
    color: #000;

    &:hover {
      color: #767676;
    }

    &::after {
      background-color: #000;
    }

    &:hover::after {
      background-color: #767676;
    }
  }
}
