@use "../globals/forward.scss" as *;

.projects {
  position: relative;
  z-index: 0;
  background-image: url(../assets/images/Desktop/05_project/Projects_BG.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects__inner {
  padding: 32px 0 48px;

  @include tb {
    padding: 16px 0px 32px;
  }
}

.projects__title {
  font-size: 106px;
  font-weight: 700;
  max-width: 972px;
  margin: 0 auto 32px;
  text-align: right;
  color: #ffffff;

  @include tb {
    font-size: 48px;
    max-width: 344px;
    margin: 0 auto 16px;
  }
}

.projects__heading {
  max-width: 966px;
  margin: 0 auto 16px;
  font-size: 34px;
  color: #ffffff;
  font-weight: 700;

  @include tb {
    font-size: 22px;
    max-width: 344px;
  }
}

.projects__heading--small {
  @extend .projects__heading;
  max-width: 842px;

  @include tb {
    max-width: 344px;
  }
}

.projects__text-bg {
  padding: 24px 0 26px;
  width: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  @include tb {
    position: relative;
    padding: 16px 0px;
    background-image: none;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0px;
      display: block;
      width: 100vw;
      height: 266px;
      background-size: cover;
      background-position: center;
      background-image: url(../assets/images/Mobile/MB_BG_Gradient-band_h266v.png);
    }
  }

  & > p {
    max-width: 966px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.4;

    @include tb {
      max-width: 344px;
      font-size: 14px;
    }
  }
}

.projects__text-bg--small {
  @extend .projects__text-bg;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

  @include tb {
    position: relative;
    padding: 16px 0px;
    background-image: none;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0px;
      display: block;
      width: 100vw;
      height: 266px;
      background-size: cover;
      background-position: center;
      background-image: url(../assets/images/Mobile/MB_BG_Gradient-band_h266v.png);
    }
  }

  & > p {
    max-width: 842px;
    margin: 0 auto;

    @include tb {
      max-width: 344px;
      font-size: 14px;
    }
  }
}

.projects1 {
  margin: 32px auto 48px;
  transform: translateX(-36px);
  @include tb {
    width: 296px;
    height: 197px;
    margin: 0 auto 32px;
    transform: none;
  }
}

.projects2 {
  margin: 32px auto 48px;
  transform: translateX(36px);
  @include tb {
    width: 296px;
    height: 197px;
    margin: 0 auto 32px;
    transform: none;
  }
}

.projects3 {
  margin: 32px auto 0;
  transform: translateX(-36px);
  @include tb {
    width: 296px;
    height: 197px;
    margin: 0 auto;
    transform: none;
  }
}

.projects__image {
  width: 100%;
  height: 100%;
  border-radius: 4px;

  @include tb {
    border-radius: 3px;
  }
}
